//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import AppHeader from '@/components/AppHeader'
import SchemaFooter from '@/components/modules/SchemaFooter'
// import CookieNotice from '@/components/modules/CookieNotice'

export default {
	components: {
		AppHeader,
		SchemaFooter
	},
	data() {
		return {
			slug: '',
			headertype: false,
			headerMenu: true,
			padd: 0,
			scrollwidth: 15,
		}
	},
	watch: {
		$route(to, from) {
			setTimeout(() => {
				this.updatePad()
			}, 750)
		},
	},
	beforeRouteUpdate(to, from, next) {
		next()
	},
	mutations: {},
	computed: {
		getPad() {
			return { 'padding-top': this.padd + 'px' }
		},
		getPadScroll() {
			return {
				//  'right': this.scrollwidth + 'px'
				width: 'calc(100% - ' + this.scrollwidth + 'px)',
			}
		},
	},
	methods: {
		updateScroll() {
			//  console.log('SCROLL UPDATE')
			const scrollbar = document.querySelector('.page')
			scrollbar.scrollTop = 0
		},
		updateScrollPad() {
			const el = document.querySelector('.page')
			const width = el.offsetWidth - el.clientWidth
			this.scrollwidth = width
		},
		updatePad() {
			const height = document.querySelector('#header').clientHeight
			//  console.log('Update Pad', height)
			this.padd = height
		},
	},
	beforeCreate() {
		//  console.log(`LAYOUT -beforeCreate`)
		//  this.$store.dispatch('loadData') // get cms data
	},
	created() {
		//  console.log(`LAYOUT -created`)
	},
	beforeMount() {
		this.slug = this.$router.currentRoute.path.substring(
			this.$router.currentRoute.path.lastIndexOf('/') + 1
		)
		// console.log(`LAYOUT -beforeMount `, this.slug)
	},
	mounted() {
		// console.log(`LAYOUT -mounted`)
		this.$nextTick(this.updatePad())
		this.$nextTick(this.updateScrollPad())
		const that = this
		setTimeout(() => {
			if (process.browser) {
				// console.log('resize event handler')
				window.addEventListener('resize', function () {
					that.updatePad()
					that.updateScrollPad()
				})
			}
		}, 0)
	},
	beforeUpdate() {
		// console.log(`LAYOUT -beforeUpdate`)
	},
	updated() {
		// console.log(`LAYOUT -updated`)
	},
	beforeDestroy() {
		// console.log(`LAYOUT -beforeDestroy`)
	},
	destoyed() {
		// console.log(`LAYOUT -destoyed`)
		window.removeEventListener('resize', this.updatePad())
		window.removeEventListener('resize', this.updateScrollPad())
	},
}
